import React, {useEffect, useRef, useState} from 'react';
import './Chatbot.css';
import axios from 'axios';
import {Rating} from 'react-simple-star-rating'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface Message {
    text: string;
    fromUser: boolean;
    link: string
}

interface ChatBotProps {
    closeChat: () => void;
}

const initialMessage: Message = {
    text: 'Zdravo, kako Vam možemo pomoći?',
    fromUser: false,
    link: ''
}

const Chatbot: React.FC<ChatBotProps> = ({closeChat}) => {
    const [messages, setMessages] = useState<Message[]>([initialMessage]);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [sessionId, setSessionId] = useState(false);
    const [option, setOption] = useState('');
    const [activeButton, setActiveButton] = useState('');
    const [rating, setRating] = useState(0)
    const [messageCount, setMessageCount] = useState(0);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [notificationShown, setNotificationShown] = useState(false);


    const notify = () => toast("Zelis da me ocjenis? Vrati se na vrh");

    useEffect(() => {
        if (messageCount >=5  && !notificationShown) {
            notify();
            setNotificationShown(true);

        }
    }, [messageCount,notificationShown]);

    const handleRating = (rate: number) => {
        setRating(rate)
        console.log('log', rate)
        axios.get(`https://aixtestdomain.online/fwefwe/${rate}`).then((r) => {
            // console.log('response', r);
        })
    }
    useEffect(() => {
        // Check if session ID is already fetched
        if (!sessionId) {
            const fetchSessionId = async () => {
                try {
                    const sessionIdResp = await axios.get('https://aixtestdomain.online/uuid_generation');
                    setSessionId(sessionIdResp.data.session_id);
                } catch (error) {
                    console.error('Error fetching session ID:', error);
                }
            };

            fetchSessionId();
        }
    }, [sessionId]);

    // const handleOptionSelect = async (selectedOption: any) => {
    //     setActiveButton(selectedOption);
    //     switch (selectedOption) {
    //         case 'Proizvod':
    //             setOption('product')
    //             break;
    //         case 'Zalba':
    //             setOption('complaint')
    //             break;
    //         case 'Otalo':
    //             setOption('other')
    //             break;
    //         default:
    //             setOption('')
    //     }
    // };

    const handleMessageSend = async () => {
        if (inputText.trim() !== '') {
            const newMessage: Message = {text: inputText, fromUser: true, link: ''};
            setMessages(prevMessages => [...prevMessages, newMessage]);
            setInputText('');
            setIsLoading(true);
            try {
                const url = encodeURIComponent(newMessage.text)
                let urlToSend = ``
                let linkToShow = ''

                urlToSend = `https://aixtestdomain.online/query_old/${sessionId}?question=${url}`

                const response = await axios.get(urlToSend);
                console.log('res', response)

                const parsedResponse = response.data.answer.split('/n').join('\n');
                const botResponse: Message = {text: parsedResponse, fromUser: false, link: linkToShow};
                setMessages(prevMessages => [...prevMessages, botResponse]);
                setMessageCount(messageCount + 1);


            } catch (error) {
                console.error('Error sending message:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };
    const renderMessageContent = (message: Message) => {
        const textWithLinks = message.text.replace(
            /\[([^\]]+)\]\(([^)]+)\)/g,
            `<a href="$2" target="_blank">$1</a>`
        );

        return <div dangerouslySetInnerHTML={{__html: textWithLinks}}/>;
    };
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleMessageSend();
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [messages]);



    return (
        <div className="chatbot-container">
            <div className="chatbot-header">
                {/*<img src={require('../assets/images/logo.png')} alt="Logo" className="header-logo"/>*/}
                <h2 className="font">Mini Bambini</h2>
                <button onClick={closeChat}>X</button>

            </div>
            <div className="chatbot-messages">
                {messageCount >= 5 &&
                    <div className="star-container">
                        <div className="star">
                            Ocjenite Vaše zadovoljstvo
                            <Rating onClick={handleRating} initialValue={rating}/>
                        </div>
                    </div>
                }

                {/*<div className="chatbot-options">*/}
                {/*    <p className="font font-20">O cemu zelite da pricate danas?</p>*/}
                {/*    <button className={`option-button  ${activeButton === 'Proizvod' ? 'active' : ''}`}*/}
                {/*            onClick={() => handleOptionSelect('Proizvod')}>Proizvod*/}
                {/*    </button>*/}
                {/*    <button className={`option-button ${activeButton === 'Zalba' ? 'active' : ''}`}*/}
                {/*            onClick={() => handleOptionSelect('Zalba')}>Zalba*/}
                {/*    </button>*/}
                {/*    <button className={`option-button ${activeButton === 'Otalo' ? 'active' : ''}`}*/}
                {/*            onClick={() => handleOptionSelect('Otalo')}>Otalo*/}
                {/*    </button>*/}
                {/*</div>*/}
                {messages.map((message, index) => (
                    <div key={index}
                         className={`chat-message ${message.fromUser ? 'user-message' : 'bot-message'}`}>
                        {!message.fromUser && <div className="bot-avatar"></div>}
                        <div className="message-content">{renderMessageContent(message)}</div>
                        {/*{!message.fromUser && message.link && message.link !== '' && (*/}
                        {/*    <div className="message-link">*/}
                        {/*        <a href={message.link} target="_blank" rel="noopener noreferrer" style={{color:'wheat'}}>Pogledajte ovdje detaljnije</a>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                ))}
                <div ref={messagesEndRef}/>

                {isLoading && (
                    <div className="loading-dots">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                    </div>
                )}
            </div>
            <div className="chatbot-input font">
                <input
                    type="text"
                    placeholder="Pošaljite poruku..."
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    disabled={isLoading}
                    onKeyPress={handleKeyPress}
                />
                <button onClick={handleMessageSend} disabled={isLoading}
                        className="send-button font"> {isLoading ? 'Čekanje...' : 'Pošalji'}

                </button>
            </div>
            <ToastContainer />
        </div>


    );

};

export default Chatbot;
