// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.css-ezjvx4 {
 position: fixed;
 z-index: 200000;
 bottom: 20px;
 right: 20px;
 left: initial;
 width: initial;
 height: 52px;
 padding: 10px 12px;
 box-sizing: border-box;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 10px;
 border-radius: 26px;
 background-color: rgb(27, 3, 52);
 box-shadow: rgba(0, 0, 0, 0.12) 0px 6.359px 19.076px 0px, rgba(60, 66, 87, 0.08) 0px 19.076px 44.51px 0px, rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
 border: none;
 color: rgb(255, 255, 255);
 cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;;;AAIA;CACC,eAAe;CACf,eAAe;CACf,YAAY;CACZ,WAAW;CACX,aAAa;CACb,cAAc;CACd,YAAY;CACZ,kBAAkB;CAClB,sBAAsB;CACtB,aAAa;CAEb,uBAAuB;CAEvB,mBAAmB;CACnB,SAAS;CACT,mBAAmB;CACnB,gCAAgC;CAChC,kJAAkJ;CAClJ,YAAY;CACZ,yBAAyB;CACzB,eAAe;AAChB","sourcesContent":[".App {\r\n  text-align: center;\r\n}\r\n\r\n.App-logo {\r\n  height: 40vmin;\r\n  pointer-events: none;\r\n}\r\n\r\n@media (prefers-reduced-motion: no-preference) {\r\n  .App-logo {\r\n    animation: App-logo-spin infinite 20s linear;\r\n  }\r\n}\r\n\r\n.App-header {\r\n  background-color: #282c34;\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  font-size: calc(10px + 2vmin);\r\n  color: white;\r\n}\r\n\r\n.App-link {\r\n  color: #61dafb;\r\n}\r\n\r\n@keyframes App-logo-spin {\r\n  from {\r\n    transform: rotate(0deg);\r\n  }\r\n  to {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n\r\n\r\n\r\n.css-ezjvx4 {\r\n position: fixed;\r\n z-index: 200000;\r\n bottom: 20px;\r\n right: 20px;\r\n left: initial;\r\n width: initial;\r\n height: 52px;\r\n padding: 10px 12px;\r\n box-sizing: border-box;\r\n display: flex;\r\n -webkit-box-pack: center;\r\n justify-content: center;\r\n -webkit-box-align: center;\r\n align-items: center;\r\n gap: 10px;\r\n border-radius: 26px;\r\n background-color: rgb(27, 3, 52);\r\n box-shadow: rgba(0, 0, 0, 0.12) 0px 6.359px 19.076px 0px, rgba(60, 66, 87, 0.08) 0px 19.076px 44.51px 0px, rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;\r\n border: none;\r\n color: rgb(255, 255, 255);\r\n cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
