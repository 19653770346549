import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import Chatbot from "./components/Chatbot";

function App() {
    const [showChat, setShowChat] = useState(false);

    const openChat = () => {
        setShowChat(true);
    };

    const closeChat = () => {
        setShowChat(false);
    };
    return (
        <div>
            {!showChat &&
                    <div onClick={openChat} color="#4E4AFF" className="css-ezjvx4 etyqcsi4">
                        <span color="#ffffff" className="css-1t5jr13 etyqcsi5">Imaš pitanje? Razgovarajmo!</span>
                    </div>
            }
            {showChat &&
                <div>
                    <Chatbot closeChat={closeChat} />
                </div>
            }
        </div>
    );
}

export default App;
